// eslint-disable-next-line

import React, { Component } from "react";

import idominguez from "../assets/portraits/resized/idominguez.png";

// import dcampos from "../assets/dcampos_portrait_2023_006.JPG";
import dcampos from "../assets/portraits/LIVE_PORTRAITS/david__portrait__2023.jpeg";

// import msmith from "../assets/portraits/resized/msmith.png";
import msmith from "../assets/portraits/LIVE_PORTRAITS/mike__portrait__2023.jpeg";

import tjoseph from "../assets/portraits/resized/tjoseph.png";
import scostas from "../assets/portraits/resized/scostas_2.png";
import adawson from "../assets/portraits/resized/adawson.png";


class Team extends Component {
  render() {
    return (
        <div id='team' className='container custom-section-container'>
            <h1 className="custom-page-title">
                OUR PEOPLE
            </h1>
            <br />
            <div className='row'>
                {/* ian */}
                <div className='col-md-6 mb-6 mb-md-0'>
                    <div className=' py-4 h-100'>
                        <div className='card-body text-center'>

                            <img src={idominguez} height='200px' style={{margin: '0px'}} alt='ian'></img>
                            
                            <hr className='my-4' />
                            <div className=''>
                                <h5><strong><a href="https://www.linkedin.com/in/iancdominguez/" target="_blank" rel="noopener noreferrer" style={{color:"rgb(101, 149, 138)"}}>Ian Dominguez</a></strong></h5>
                                <h6>Founder &amp; CIO</h6>
                                <br />
                                <p style={{fontSize: '.9em'}}>Ian founded Delta Emerald to streamline information in the cannabis sector. He leads both investments and operations, drawing on a decade of experience in using behavioral data to capitalize on major consumer trends at hedge funds and family offices.</p>
                                <p style={{fontSize: '.9em'}}>Ian started his career in JP Morgan's Leveraged Finance group and graduated from the University of Virginia, earning a B.S. in Commerce with concentrations in Finance and Accounting. Ian lives in Miami with his wife, Sophia.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* david */}
                <div className='col-md-6 mb-6 mb-md-0'>
                    <div className=' py-4 h-100'>
                        <div className='card-body text-center'>
                            <img src={dcampos} height='200px' style={{margin: '0px'}} alt='david'></img>
                            <hr className='my-4' />
                            <div className=''>
                                <h5><strong><a href="https://www.linkedin.com/in/dcamposliz/" target="_blank" rel="noopener noreferrer" style={{color:"rgb(101, 149, 138)"}}>David Campos</a></strong></h5>
                                <h6>Data Architect</h6>
                                <br />
                                <p style={{fontSize: '.9em'}}>David loves to build internet and data machines that solve significant problems.</p>
                                <p style={{fontSize: '.9em'}}>David has a background in product management, software engineering, and data science. David holds a B.A. in Economics and Spanish from UC Santa Barbara, and a Master of Information &amp; Data Science from UC Berkeley. David enjoys producing electronic music, weightlifting, painting, and traveling.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* mike */}
                <div className='col-md-6 mb-6 mb-md-0'>
                    <div className=' py-4 h-100'>
                        <div className='card-body text-center'>
                            <img src={msmith} height='200px' style={{margin: '0px'}} alt='mike'></img>
                            <hr className='my-4' />
                            <div className=''>
                                <h5><strong><a href="https://www.linkedin.com/in/michael-smith-07148563/" target="_blank" rel="noopener noreferrer" style={{color:"rgb(101, 149, 138)"}}>Michael Smith</a></strong></h5>
                                <h6>Principal</h6>
                                <br />
                                <p style={{fontSize: '.9em'}}>Mike is inspired by passionate founders, and helping them build better businesses using data and analytics.</p>
                                <p style={{fontSize: '.9em'}}>Mike has a background in venture investing, as well as founder and operational experience at early-stage consumer companies. Mike holds a B.A. in International Relations and Global Business from the University of Southern California, and enjoys biographical nonfiction, travel, and museums.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* tristn */}
                <div className='col-md-6 mb-6 mb-md-0'>
                    <div className=' py-4 h-100'>
                        <div className='card-body text-center'>
                            <img src={tjoseph} height='200px' style={{margin: '0px'}} alt='tristn'></img>
                            <hr className='my-4' />
                            <div className=''>
                            <h5><strong><a href="https://www.linkedin.com/in/trisxcj1/" target="_blank" rel="noopener noreferrer" style={{color:"rgb(101, 149, 138)"}}>Trist'n Joseph</a></strong></h5>
                                <h6>Data Scientist</h6>
                                <br />
                                <p style={{fontSize: '.9em'}}>Trist'n loves tackling challenging problems and has a deep passion for using data to tell a story.</p>
                                <p style={{fontSize: '.9em'}}>Trist'n has a background in mathematics and statistics, and enjoys applying mathematical concepts to the real world. Trist'n holds a B.S. in Mathematics & Economics from Pace University, and a M.S. in Applied Data Science from Syracuse University. In his free time, Trist'n maintains a blog in Towards Data Science, where he publishes data science-related articles.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* shyra */}
                {/* <div className='col-md-6 mb-6 mb-md-0'>
                    <div className=' py-4 h-100'>
                        <div className='card-body text-center'>
                            <img src={scostas} height='200px' style={{margin: '0px'}} alt='shyra'></img>
                            <hr className='my-4' />
                            <div className=''>
                            <h5><strong><a href="https://www.linkedin.com/in/shyra-costas/" target="_blank" rel="noopener noreferrer" style={{color:"rgb(101, 149, 138)"}}>Shyra Costas</a></strong></h5>
                                <h6>Associate</h6>
                                <br />
                                <p style={{fontSize: '.9em'}}>Shyra is a strategist focused on identifying exciting opportunities, connecting like-minded innovators, and enabling early businesses to thrive.</p>
                                <p style={{fontSize: '.9em'}}>Shyra has a SaaS background from Oracle and previously worked in media and investment management. She holds a B.A. in History from Tufts University. She loves the outdoors and enjoys rock climbing, backpacking, and softball.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='col-md-6 mb-6 mb-md-0'>
                        <div className=' py-4 h-100'>
                            <div className='card-body text-center'>
                                <img src=   {adawson} height='200px' style={{margin: '0px'}} alt='adam'></img>
                                <hr className='my-4' />
                                <div className=''>
                                <h5><strong><a href="https://www.linkedin.com/in/acldxyz/" target="_blank" rel="noopener noreferrer" style={{color:"rgb(101, 149, 138)"}}>Adam Dawson</a></strong></h5>
                                    <h6>Director of Investor Relations</h6>
                                    <br />
                                    <p style={{fontSize: '.9em'}}>Adam's drive is to discover the future through the technologies and people that shape it.</p>
                                    <p style={{fontSize: '.9em'}}>Previously, Adam was a senior leader at Leafly and an investor at Mousse Partners. He holds a B.A. in Economics from Trinity College and spends his spare time running, and endlessly searching for more cacio e pepe.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>



        
    )
  }
}


export default Team;