import React from 'react';

import unscriptedLogo from '../assets/source_logos/dev_logo.png';
import cannadataconLogo from '../assets/source_logos/cannadatacon_logo__simple.png';
import dispatchLogo from '../assets/source_logos/dev_logo.png';

const data = [
    {
      "title": "How a Leading THC Drink Brand Converts In-Store Customers",
      "date": "October 2024",
      "category": "Delta Emerald Unscripted",
      "type": "Delta Emerald Unscripted Video & Audio",
      "youtube_link": "https://www.youtube.com/watch?v=nZl6GTpYSJg&t=4s",
      "spotify_link": "https://open.spotify.com/episode/4mpNNWM9cnEpj1INlpuApz",
      "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-this-one-trick-helped-a/id1769090220?i=1000671660390"
    },
    {
      "title": "Behind the Scenes of THC Beverage Policy",
      "date": "September 2024",
      "category": "Delta Emerald Unscripted",
      "type": "Delta Emerald Unscripted Video & Audio",
      "youtube_link": "https://youtu.be/MD1pCEi64-M?si=RCpl8QFiDmmx17pj",
      "spotify_link": "https://open.spotify.com/episode/7ko30unuOI6FipgldItf7X",
      "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-behind-the-scenes-of/id1769090220?i=1000670521835"
    },
    {
      "title": "A Beer Industry Veteran's View on THC Drinks",
      "date": "September 2024",
      "category": "Delta Emerald Unscripted",
      "type": "Delta Emerald Unscripted Video & Audio",
      "youtube_link": "https://youtu.be/QCgwhaSNNc8?si=_3ZRbGcXiD-eC5qN",
      "spotify_link": "https://open.spotify.com/episode/5PuT1urXE8rVrQnImbHysz",
      "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-a-beverage-insiders/id1769090220?i=1000669866567"
    },
    {
      "title": "THC Drinks and the Digital Shelf",
      "date": "August 2024",
      "category": "Delta Emerald Unscripted",
      "type": "Delta Emerald Unscripted Video & Audio",
      "youtube_link": "https://youtu.be/Hw80MeYJzNI?si=cH7QZ_0m6JfFuXli",
      "spotify_link": "https://open.spotify.com/episode/532aVyrrbtf0VA0F8gIPtY",
      "apple_podcasts_link": "https://podcasts.apple.com/us/podcast/delta-emerald-unscripted-thc-drinks-and-the-digital-shelf/id1769090220?i=1000669866588"
    },
    {
      "title": "THC Drink and Cannabis Regulations",
      "date": "April 2024",
      "category": "CannaDataCon",
      "type": "CannaDataCon2024 Video",
      "youtube_link": "https://www.youtube.com/watch?v=CLp0yFPAc84"
    },
    {
      "title": "Delta Emerald's Views on THC Drinks",
      "date": "April 2024",
      "category": "CannaDataCon",
      "type": "CannaDataCon2024 Video",
      "youtube_link": "https://www.youtube.com/watch?v=zvVjbTvNmsU&t=16s"
    },
    {
      "title": "The Great Convergence of Beer and THC Drinks",
      "date": "October 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/the-great-convergence-of-beer-and"
    },
    {
      "title": "The Win-Win for THC Drinks, Part II",
      "date": "September 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-ii"
    },
    {
      "title": "The Win-Win for THC Drinks, Part I",
      "date": "September 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/the-win-win-for-thc-drinks-part-i"
    },
    {
      "title": "Alcohol Regulators Can't Keep Cannabis Out of Their Mouths",
      "date": "August 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/why-alcohol-regulators-cant-keep"
    },
    {
      "title": "Tilray's Trojan THC Horse",
      "date": "August 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/tilrays-trojan-thc-horse"
    },
    {
      "title": "What Cannabinoid Regulators Think",
      "date": "July 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/what-cannabinoid-regulators-think"
    },
    {
      "title": "Dispatch from Mecca (of THC Drinks)",
      "date": "June 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/dispatch-from-mecca-of-thc-drinks"
    },
    {
      "title": "Bigger Than Alcohol, Part III",
      "date": "March 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol-part-iii"
    },
    {
      "title": "The Florida Hemp Opportunity",
      "date": "February 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/the-florida-hemp-opportunity"
    },
    {
      "title": "Bigger Than Alcohol, Part II",
      "date": "January 2024",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol-part-ii"
    },
    {
      "title": "Bigger Than Alcohol, Part I",
      "date": "November 2023",
      "category": "Delta Dispatch",
      "type": "The Delta Dispatch Essay",
      "substack_link": "https://deltaemerald.substack.com/p/bigger-than-alcohol"
    }
  ];

const DeltaEmeraldContent = () => {
  const getLogo = (platform) => {
    switch (platform) {
      case 'youtube':
        return 'https://img.shields.io/badge/YouTube-FF0000?style=for-the-badge&logo=youtube&logoColor=white';
      case 'spotify':
        return 'https://img.shields.io/badge/Spotify-1ED760?style=for-the-badge&logo=spotify&logoColor=white';
      case 'apple':
        return 'https://img.shields.io/badge/Apple_Podcasts-9933CC?style=for-the-badge&logo=apple-podcasts&logoColor=white';
      default:
        return null;
    }
  };

  const getCategoryLogo = (category) => {
    switch (category) {
      case 'Delta Emerald Unscripted':
        return unscriptedLogo;
      case 'CannaDataCon':
        return cannadataconLogo;
      case 'Delta Dispatch':
        return dispatchLogo;
      default:
        return null;
    }
  };

  const renderLinks = (item) => {
    const links = [];
    if (item.substack_link) links.push(
      <div key="substack">
        <a 
          href={item.substack_link} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ color: 'rgb(101, 149, 138)', textDecoration: 'underline' }}
        >
          Substack
        </a>
      </div>
    );
    if (item.youtube_link) links.push(
      <div key="youtube">
        <a href={item.youtube_link} target="_blank" rel="noopener noreferrer">
          <img src={getLogo('youtube')} alt="YouTube" style={{ height: '24px' }} />
        </a>
      </div>
    );
    if (item.spotify_link) links.push(
      <div key="spotify">
        <a href={item.spotify_link} target="_blank" rel="noopener noreferrer">
          <img src={getLogo('spotify')} alt="Spotify" style={{ height: '24px' }} />
        </a>
      </div>
    );
    if (item.apple_podcasts_link) links.push(
      <div key="apple">
        <a href={item.apple_podcasts_link} target="_blank" rel="noopener noreferrer">
          <img src={getLogo('apple')} alt="Apple Podcasts" style={{ height: '24px' }} />
        </a>
      </div>
    );
    return links;
  };

  return (
    <div id="DeltaEmeraldContent" className="container custom-section-container">
      <h1 className="custom-page-title">PUBLIC THC BEVERAGE RESEARCH</h1>
      <br />
      <div className="row">
        <table className="table table-striped larger-text">
          <thead>
            <tr>
              <th>Title</th>
              <th></th>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">
                  {getCategoryLogo(item.category) && (
                    <img 
                      src={getCategoryLogo(item.category)} 
                      alt={`${item.category} logo`} 
                      style={{ height: '', width: '35px', margin: '5px', verticalAlign: 'middle' }} 
                    />
                  )}
                </td>
                <td className="align-middle">{item.title}</td>
                <td className="align-middle">
                  <div className="d-flex align-items-center justify-content-start">
                    {renderLinks(item)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <style jsx>{`
        .larger-text {
          font-size: 1.1rem;
        }
        .larger-text th {
          font-size: 1.2rem;
        }
        .table td {
          vertical-align: middle;
        }
        .table td > div {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      `}</style>
    </div>
  );
};

export default DeltaEmeraldContent;